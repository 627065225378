<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1 v-if="monthName.length > 0 && monthName !== 'loading'">Invoices for {{ monthName}} {{ year }}</h1>
        <h1 v-if="monthName.length > 0 && monthName === 'loading'">The report is loading</h1>
        <h1 v-if="monthName.length === 0">Please choose a month to view the report</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-3">
        <v-date-picker
          v-if="hidePicker === false"
          v-model="date"
          type="month"
        ></v-date-picker>
        <v-btn small @click="hidePicker = false" v-else>Show calendar</v-btn>
      </v-col>
      <v-col class="col-9" v-if="users.length > 0">
        <download-csv
            class="float-right"
            :name="`sage_commission_report_${monthName}.csv`"
            :data="exportUsers">
            <v-btn small>Export</v-btn>
        </download-csv>
      </v-col>
    </v-row>
    <v-row v-if="users.length > 0">
      <v-col>
        <v-tabs v-model="tab">
          <div v-for="(user, index) in users" :key="`us_${index}`">
            <v-tab v-if="user.invoices.length > 0">
              {{ user.initials }}
            </v-tab>
          </div>
          <div>
            <v-tab>Unallocated</v-tab>
          </div>
        </v-tabs>
      </v-col>
    </v-row>
    <v-row v-if="users.length > 0">
      <v-col>
        <v-tabs-items v-model="tab">
          <div v-for="(user, index) in users" :key="`us2_${index}`">
            <v-tab-item v-if="user.invoices.length > 0">
              <v-simple-table fixed-header height="400">
                <thead>
                  <tr>
                      <th>Invoice Number</th>
                      <th>Date</th>
                      <th>Customer</th>
                      <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(invoice, index) in user.invoices" :key="index">
                      <td>{{ invoice.invRef }}</td>
                      <td>{{ invoice.date }}</td>
                      <td>{{ invoice.accountRef }}</td>
                      <td>{{ invoice.netAmount | currency }}</td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-tab-item>
          </div>
          <v-tab-item>
            <v-simple-table fixed-header height="400">
              <thead>
                <tr>
                    <th>Invoice Number</th>
                    <th>Date</th>
                    <th>Customer</th>
                    <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(invoice, index) in unallocated" :key="index">
                    <td>{{ invoice.invRef }}</td>
                    <td>{{ invoice.date }}</td>
                    <td>{{ invoice.accountRef }}</td>
                    <td>{{ invoice.netAmount | currency }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <v-row v-if="users.length === 0">
      <v-col v-if="monthName.length > 0">
        <v-skeleton-loader
            v-bind="attrs"
            type="table: table-heading, table-thead, table-tbody, table-tfoot"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'SageCommissionReport',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      hidePicker: false,
      users: [],
      exportUsers: [],
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      monthName: '',
      year: '',
      total: 0,
      attrs: {
        boilerplate: false,
        elevation: 2,
      },
      tab: null,
      unallocated: [],
    };
  },
  watch: {
    date() {
      this.users = [];
      this.getInvoices();
    }
  },
  methods: {
    getInvoices() {
      this.hidePicker = true;
      this.total = 0;
      this.invoices = [];
      this.monthName = 'loading';
      this.year = '';
      const postData = {};
      postData.date = this.date;
      axios.post(`/invoices/getSageCommission.json?token=${this.token}`, postData)
      .then((response) => {
        this.users = response.data.users;
        this.unallocated = response.data.unallocated;
        this.exportUsers = response.data.exportUsers;
        this.monthName = response.data.monthName;
        this.year = response.data.year;
      });
    },
  },
};
</script>
